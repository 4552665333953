import React from "react";
import BlockContent from "@sanity/block-content-to-react"
import customBlocks from "../../customBlocks/customBlocks"
import style from "./index.module.css";

function PageBlockContact(props) {
  const title =  props.title
  const content:GatsbyTypes.SanityPageBlockContact = props.content
  return (
    <div className={style.main}>
      <div className={style.content}>
        {title &&
          <h1 className={style.title}>{title}</h1>
        }
        {content.body &&
          <BlockContent blocks={content.body} serializers={customBlocks} />
        }
        {content.formTitle &&
          <h2>{content.formTitle}</h2>
        }
        {content.formText &&
          <div>{content.formText}</div>
        }
        <form className={style.form} name="Contactformulier" method="post" action={content.formThanksPage ? `/${content.formThanksPage.slug.current}/` : "/contact/"} data-netlify="true" data-netlify-honeypot="details-field">
          <input type="hidden" name="form-name" value="Contactformulier" />
          <p className={style.hidden}>
            <label>
              Details: <input name="details-field" />
            </label>
          </p>
          <div>
            <label htmlFor="naam">Naam</label>
            <input type="text" name="Contactpersoon" id="naam" autoComplete="name" required />
          </div>
          <div>
            <label htmlFor="email">E-mailadres</label>
            <input type="email" name="E-mailadres" id="email" autoComplete="email" required />
          </div>
          <div>
            <label htmlFor="telefoon">Telefoonnummer</label>
            <input type="tel" name="Telefoonnummer" id="telefoon" autoComplete="tel" required />
          </div>
          <div>
            <label htmlFor="bericht">Bericht</label>
            <textarea name="Bericht" id="bericht" required />
          </div>
          <div>
            <button type="submit" className={style.button}>Verstuur</button>
          </div>
        </form>
        {content.formDisclaimer &&
          <div className={style.formDisclaimer}>{content.formDisclaimer}</div>
        }
      </div>
    </div>
  )
}

export default PageBlockContact

